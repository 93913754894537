@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Passero+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Orbitron&display=swap");
// Rest css
* {
  padding: 0;
  margin: 0;
}
html,
body {
  font-family: "Inter", sans-serif;
  font-size: 16px;
}
ol,
ul {
  list-style: none;
}
img {
  vertical-align: top;
  width: 100%;
  border: none;
}
a {
  color: inherit;
  text-decoration: none;
}
button {
  border: none;
  background: none;
  cursor: pointer;
}
input {
  border: none;
}
a {
  cursor: pointer;
}
// Style
.App {
  position: relative;
  overflow: hidden;
  .img-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .container {
    max-width: 1440px;
    margin: 0 auto;
    position: relative;
    padding: 0 24px;
    .page-header {
      padding-top: 70px;
      @media only screen and (max-width: 480px) {
        padding-top: 40px;
      }
      .nav-menu {
        ul {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 50px;
          li {
            color: #fff;
            text-align: center;
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;
          }
        }
      }
    }
    .page-main {
      position: relative;
      .section-intro {
        padding-top: 87px;
        display: flex;
        justify-content: center;
        gap: 41px;
        @media only screen and (max-width: 1280px) {
          gap: 30px;
        }
        @media only screen and (max-width: 986px) {
          flex-direction: column;
          align-items: center;
        }
        @media only screen and (max-width: 480px) {
          padding-top: 28px;
        }
        .content-left {
          max-width: 755px;
          width: 100%;
          @media only screen and (max-width: 1280px) {
            max-width: 600px;
          }
          @media only screen and (max-width: 986px) {
            .row-center {
              justify-content: center;
            }
          }
          @media only screen and (max-width: 480px) {
            .row-center {
              flex-direction: column;
              .title {
                padding-left: 0;
              }
            }
          }
          .img-left {
            width: 158px;
          }
          .title {
            color: #fff;
            font-family: Passero One;
            font-size: 100px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-left: 30px;
            @media only screen and (max-width: 540px) {
              font-size: 70px;
            }
          }
          .des {
            padding-top: 22px;
            color: #fff;
            font-family: Inter;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 162.023%; /* 38.885px */
            @media only screen and (max-width: 986px) {
              text-align: center;
            }
            @media only screen and (max-width: 480px) {
              font-size: 18px;
              padding-top: 0;
            }
          }
          .contact-info {
            padding-top: 63px;
            @media only screen and (max-width: 1024px) {
              padding-top: 30px;
            }
            @media only screen and (max-width: 480px) {
              padding-top: 18px;
            }
            p {
              color: #fff;
              font-family: Orbitron;
              font-size: 30px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              letter-spacing: 2.55px;
              @media only screen and (max-width: 986px) {
                text-align: center;
              }
              @media only screen and (max-width: 480px) {
                font-size: 24px;
              }
            }
            .field {
              margin-top: 11px;
              border-radius: 8px;
              background: #fff;
              box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
              width: 700px;
              height: 67px;
              display: flex;
              word-break: break-word;

              // justify-content: center;
              padding-left: 20px;
              align-items: center;
              font-size: 20px;
              @media only screen and (max-width: 1280px) {
                width: 570px;
              }
              @media only screen and (max-width: 648px) {
                width: auto;
              }
              @media only screen and (max-width: 480px) {
                height: 45px;
              }
            }
          }
        }
        .img-right {
          padding-top: 80px;
          width: 456px;
          @media only screen and (max-width: 986px) {
            padding-top: 0;
          }
          @media only screen and (max-width: 540px) {
            width: auto;
          }
        }
      }
      .section-tokenomics {
        padding-top: 79px;
        display: flex;
        justify-content: center;
        gap: 69px;
        @media only screen and (max-width: 1024px) {
          padding-top: 59px;
          gap: 49px;
        }
        @media only screen and (max-width: 986px) {
          flex-direction: column;
          align-items: center;
          gap: 30px;
        }
        @media only screen and (max-width: 480px) {
          padding-top: 30px;
        }
        .img-left {
          width: 745px;
          @media only screen and (max-width: 886px) {
            width: auto;
          }
        }
        .content-right {
          max-width: 550px;
          padding-top: 40px;
          @media only screen and (max-width: 986px) {
            padding-top: 0;
          }
          .title {
            color: #fff;
            text-align: center;
            font-family: Orbitron;
            font-size: 30px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 2.55px;
            span {
              font-weight: 800;
            }
            @media only screen and (max-width: 480px) {
              font-size: 24px;
            }
          }
          .des {
            padding-top: 50px;
            @media only screen and (max-width: 1024px) {
              padding-top: 30px;
              padding-bottom: 20px;
            }
            color: #fff;
            text-align: center;
            font-family: Inter;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 154.523%; /* 37.085px */
            padding-bottom: 50px;
            @media only screen and (max-width: 480px) {
              font-size: 18px;
              padding: 14px 0;
            }
          }
          .group-field {
            padding-top: 39px;
            @media only screen and (max-width: 1024px) {
              padding-top: 20px;
            }
            @media only screen and (max-width: 480px) {
              padding-top: 14px;
            }
            .label {
              color: #fff;
              font-family: Orbitron;
              font-size: 24px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              letter-spacing: 2.04px;
              @media only screen and (max-width: 986px) {
                text-align: center;
              }
              @media only screen and (max-width: 480px) {
                font-size: 18px;
              }
            }
            .field {
              padding: 0 42px;
              margin-top: 14px;
              border-radius: 8px;
              background: #fff;
              word-break: break-word;

              color: #000;
              text-align: center;
              font-family: Orbitron;
              font-size: 40px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              letter-spacing: 3.4px;
              @media only screen and (max-width: 480px) {
                padding: 0 24px;
                font-size: 24px;
              }
            }
            .field-2 {
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }
      .section-bottom {
        display: flex;
        justify-content: center;
        padding-top: 24px;
        .img-bottom {
          width: 1090px;
        }
      }
    }
  }
  .page-footer {
    position: relative;
    display: flex;
    justify-content: space-between;
    background: #fff;
    margin-bottom: 42px;
    padding: 18px 110px;
    @media only screen and (max-width: 1280px) {
      padding: 18px 60px;
    }
    @media only screen and (max-width: 886px) {
      flex-direction: column;
      align-items: center;
      gap: 12px;
      padding: 12px 30px;
    }
    @media only screen and (max-width: 480px) {
      margin-bottom: 24px;
    }
    .copyright {
      color: #000;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      @media only screen and (max-width: 986px) {
        font-size: 18px;
      }
      @media only screen and (max-width: 480px) {
        font-size: 14px;
      }
    }
    ul {
      display: flex;
      align-items: center;
      gap: 31px;
      @media only screen and (max-width: 1024px) {
        gap: 18px;
      }
      @media only screen and (max-width: 480px) {
        gap: 12px;
      }
      li {
        color: #000;
        text-align: center;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @media only screen and (max-width: 986px) {
          font-size: 18px;
        }
        @media only screen and (max-width: 480px) {
          font-size: 14px;
        }
      }
    }
  }
  .row-center {
    display: flex;
    align-items: center;
  }
}
